<template>
  <div class="header">
  <el-row >
    <el-col :sm="8" class="header-logo">
      <img class="header-logo-img" src="@/assets/molinks.jpg">
      <span class="header-logo-text">Molinks</span>
    </el-col>
    <el-col :sm="16" class="heaer-right">
      <Menu v-model:smBtn="headerSmBtnText" v-on:cSmBtn="headleHeaderSmBtn()"></Menu>
    </el-col>
    <el-col class="header-sm-btn hidden-sm-and-up" ><span @click="headleHeaderSmBtn()">{{headerSmBtnText}}</span></el-col>
  </el-row>
  </div>
</template>

<script>
import Menu from './menu'
export default {
  name: 'Header',
  components: {
    Menu
  },data(){
    return {
      headerSmBtnText:'三'
    }
  },methods:{
    headleHeaderSmBtn(){
      this.headerSmBtnText = this.headerSmBtnText=='三'?'X':'三'
    }
  }
}
</script>

<style scoped lang="scss">
  .header{
    flex-shrink: 0;
    height: 60px;
    background-color: #4C4C4C;
    color: var(--text_color);
    text-align: center;
    line-height: 60px;
    padding: 0;
    z-index: 10;
    position: fixed;
    width: 100%;

  }

  .header-sm-btn{
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    z-index: 11;
    font-size: 1.8rem;
    color: var(--text_color);
    font-weight: 500;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
  }

  .header-logo{
    display: flex;
    justify-content: center;
    align-items: center;


    .header-logo-img{
      height: 45px;
    }
    .header-logo-text{
      margin-left: 10px;
      font-size: 2rem;
      color: var(--text_color);
    }
  }

</style>
