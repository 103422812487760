<template>
  <div class="page_container">
    <Header></Header>
    <Main></Main>
  </div>

</template>


<script>
import 'element-plus/lib/theme-chalk/display.css';
import '@/styles/home.css';
import 'animate.css'
import Header from './header.vue'
import Main from "@/views/home/main";

export default {
  name: 'Home',
  components: {
    Main,
    Header
  },
  mounted(){
    // document.body.style.setProperty('--br_color', '#7F583F');
  },
  data() {
    return {

    };
  },
}

</script>


<style scoped lang="scss">

.page_container{
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
}


</style>
