<template>
  <transition name="slide-fade">
  <div id="header-nemu" :class="header_nemu_class" @click="click_header">
    <div class="header-menu-item">
      <a class="header-menu-item-title" href="#home/download">下载</a>
    </div>
    <div class="header-menu-item">
      <a class="header-menu-item-title" href="#home/price">价格</a>
    </div>
    <div class="header-menu-item">
      <a class="header-menu-item-title" href="#home/try">体验一下</a>
    </div>
<!--    <div class="header-menu-item">-->
<!--      <a class="header-menu-item-title" href="#home/contact">联系我们</a>-->
<!--    </div>-->
    <div class="header-menu-item">
      <div class="header-menu-item-title">关于</div>
      <div class="header-menu-submenu">
        <a class="header-menu-submenu-item" href="https://doc.molinks.xyz/#/blog/e">帮助和说明</a>
        <a class="header-menu-submenu-item"  href="#home/contact">联系我们</a>
        <a class="header-menu-submenu-item" href="#home/use_info">登陆和注册</a>
      </div>
    </div>
<!--    <div class="header-menu-item" >-->
<!--      <div class="header-menu-item-title">item</div>-->
<!--      <div class="header-menu-submenu">-->
<!--        <div class="header-menu-submenu-item">submenu-item</div>-->
<!--        <div class="header-menu-submenu-item">submenu-item</div>-->
<!--        <div class="header-menu-submenu-item">submenu-item</div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  </transition>

</template>

<script>


export default {
  name: 'Menu',
  components: {},
  props:{
    smBtn:{
      type:String
    },
  },
  data() {
    return {
      header_nemu_class: 'header-nemu-horizontal',
      clientWidth: 0,
    };
  },
  watch:{
    smBtn(){
      const that = this;
      if(that.smBtn=='X'){
        that.header_nemu_class = 'header-nemu-vertical'
      }else{
        that.header_nemu_class = 'header-nemu-vertical header-nemu-vertical-hide'
      }
    }
  },
  mounted() {
    // 动态设置背景图的高度为浏览器可视区域高度

    // 首先在Virtual DOM渲染数据时，设置下背景图的高度．
    this.clientWidth = document.documentElement.clientWidth;
    // 然后监听window的resize事件．在浏览器窗口变化时再设置下背景图高度．
    const that = this;
    let temp = function() {
      that.clientWidth = document.documentElement.clientWidth;
      if(that.clientWidth<768){
        if(that.smBtn=='X'){
          that.header_nemu_class = 'header-nemu-vertical'
        }else{
          that.header_nemu_class = 'header-nemu-vertical header-nemu-vertical-hide'
        }
      }else{
        that.header_nemu_class = 'header-nemu-horizontal'

      }
    }
    window.onresize = temp;
    temp()
  },
  methods: {
    click_header(){
      if(this.smBtn=='X' && this.header_nemu_class == 'header-nemu-vertical'){
        this.$emit('cSmBtn')

      }
    }
  }
}

</script>

<style scoped lang="scss">



#header-nemu{

  display: flex;
  color: var(--text_color);
  background-color: #4C4C4C;
  .header-menu-submenu,.header-menu-item-title{
    white-space: nowrap;

  }
  a{
    display: block;
    color: var(--text_color);
  }
}




.header-nemu-horizontal{
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.header-nemu-horizontal .header-menu-item{

  position: relative;
  text-align: center;
}
.header-menu-item-title{
  font-size: 1.2rem;
}

.header-nemu-horizontal .header-menu-item-title{
  padding: 0 2rem;
}

.header-menu-submenu:after{
  content:  ' ';
  width: 0;
  height: 0;
  right: 10px;
  top: -37px;
  position: absolute;
  border-top: 7px solid transparent;
  border-right: 10px solid var(--boder_color);
  border-bottom: 7px solid transparent;
  visibility: visible;
  transition: all 0.5s;
}

.header-menu-item:hover > .header-menu-submenu:after{
  transform: rotate(-90deg);
  transition: all 0.5s;
}

.header-nemu-horizontal .header-menu-submenu{
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 60px;
  opacity:1;
  background-color: #4C4C4C;

}

.header-menu-item-title:hover,.header-menu-submenu-item:hover{
  background-color: var(--boder_color);
  color: var(--text_color);
}


.header-nemu-horizontal .header-menu-item:hover .header-menu-submenu{
  visibility: visible;
  animation: fadeIn .5s;
}


.header-nemu-vertical{
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(99vh - 60px);
  animation: fadeIn .5s;
}

.header-nemu-vertical-hide{
  display:none!important;
}

.header-nemu-vertical .header-menu-item{
  width: 100%;
  text-align: left;
  border-bottom: rgba(225,255,255,.3) solid 1px;
}
.header-nemu-vertical .header-menu-item-title{
  width: 100%;
  text-align: left;
  padding-left: 2rem;
}

.header-menu-submenu-item{
  padding: 0 3rem;
  border-top: rgba(225,255,255,.3) solid 1px;
}

</style>
